// 
// tables.scss
//

//Table centered (Custom)
.table-centered {
    th,
    td {
        vertical-align: middle !important;
    }
}


// Custom table components (Custom)
.table {
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }

    .action-icon {
        color: $gray-600;
        font-size: 1.2rem;
        display: inline-block;
        padding: 0 3px;

        &:hover {
            color: $gray-700;
        }
    }
}

// Tables fluid
.table-nowrap {
    th,
    td {
        white-space: nowrap;
    }
}

.table td {
    padding: 1px 3px;
    vertical-align: middle;
}

.ellipsis-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

tr:hover {
    background-color: lightgray;
}